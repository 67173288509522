import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
let PublicView = class PublicView extends Vue {
};
PublicView = __decorate([
    Component({
        components: {},
    })
], PublicView);
export default PublicView;
